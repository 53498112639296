import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '../../../../localization/icons/InfoIcon';
import { DisplayableModifier } from '@/domain/product/types';
import useStyles from '../styles';
import { SodiumWarningIcon } from '@/clientCore/components/SodiumWarningDisclaimer/components/SodiumWarningIcon';
import useSodiumAlertForStore from '@/clientCore/components/SodiumWarningDisclaimer/hooks/useSodiumAlertForStore';

interface ProductInfoProps {
  name: string;
  formattedUnitPrice: string;
  showPrice?: boolean;
  lowerCaseCategory?: string;
  description?: string;
  sizes?: DisplayableModifier[];
  handleInfoButtonClick?: () => void;
  categoryName?: string;
  formattedSelectedOption?: string;
  sodiumWarning?: boolean;
}

const ProductInfo = (props: ProductInfoProps): JSX.Element => {
  const classes = useStyles();
  const {
    name,
    sizes,
    description,
    lowerCaseCategory,
    handleInfoButtonClick,
    formattedUnitPrice,
    categoryName,
    showPrice = true,
    formattedSelectedOption,
    sodiumWarning = false
  } = props;
  const { isShowSodiumAlertForStore } = useSodiumAlertForStore();
  const showSodiumWarning = isShowSodiumAlertForStore && sodiumWarning;
  // Define if the size name will show up with the product name, only for drinks category
  const isDrinksAndHasOnlyOneSize = lowerCaseCategory === 'drinks' && sizes?.length === 1;

  return (
    <div className={classes.priceAndNameContainer}>
      <Grid className={classes.productInfoContainer}>
        <Typography
          className={classes.title}
          data-testid="localized-product-tile-name"
        >
          {name}{isDrinksAndHasOnlyOneSize ? ` - ${sizes[0].name}` : ''}
        </Typography>
        {description && categoryName ? (
          <IconButton
            aria-label="Product Info"
            aria-haspopup="dialog"
            disableFocusRipple
            disableRipple
            className={classes.infoIcon}
            onClick={handleInfoButtonClick}
          >
            <InfoIcon />
          </IconButton>
        ) : null}
      </Grid>
      {formattedSelectedOption && (
        <Typography
          className={classes.price}
          data-testid="localized-product-tile-selected-option"
        >
          {formattedSelectedOption}
        </Typography>
      )}
      {showPrice && (
        <Typography
          className={classes.price}
          data-testid="localized-product-tile-price"
        >
          {formattedUnitPrice}
        </Typography>
      )}
      { showSodiumWarning
        && (
        <Typography
          className={classes.sodiumWarning}
          data-testid="localized-product-tile-sodium-warning"
        >
          <SodiumWarningIcon width="116" height="14" />
        </Typography>
        )}
    </div>
  );
};

export default ProductInfo;
