import React from 'react';
import Grid from '@material-ui/core/Grid';
import ProductId from '../../ProductId';
import useStyles from './styles';
import InfoRail from '../InfoRail';
import ProductInfoContent from './ProductInfoContent/ProductInfoContent';
import ProductImage from './ProductImage';
import SoldOutTile from './SoldOutTile';
import ProductInfo from './ProductInfo';
import ProductDropdownsWithAddToCart from './ProductDropdownsWithAddToCart';
import RenderNutritionInfo from './RenderNutritionInfo';
import {
  LocalizedProductTileProps
} from '@/common/components/LocalizedProductTile/LocalizedProductTile.types';
import { ButtonProps } from '@/coreComponents/buttons/SelectorButton/SelectorButton';
import useDisplayableProduct from '@/menu/categories/useDisplayableProduct';
import { replaceSpacesWithDash } from '@/common/replaceSpacesWithDash';
import { MELT } from '@/domain/constants';
import { checkSodiumWarning } from '@/clientCore/helper/checkSodiumWarning';

const defaultButtonDetails: ButtonProps = {
  ariaLabel: 'Add to Order',
  ariaLabelLoading: 'Loading add to order',
  text: 'Add',
  size: 'small',
  action: 'ADD_TO_CART'
};

const LocalizedProductTile = ({
  id,
  name,
  imageUrl,
  description,
  sizes,
  productIndex,
  additionalOptions,
  isOutOfStock,
  subcategory,
  sodiumWarning,
  categoryName,
  showQuantity = true,
  showPrice = true,
  buttonDetails,
  handleAddButtonClick,
  showErrorModal,
  telemetryEventName,
  handleAnalyticsForAddToCartClick,
  handleAnalyticsForAddToCartClickFromRail,
  handleAnalyticsForAddToCartSuccessFromRail,
  handleAnalyticsForModifierSelected,
  preSelectedAdditionalOption,
  preSelectedQuantity,
  preSelectedSize,
  isSingleStepDeal,
  oneClickDefaults,
  type,
  isMyHutBoxProduct = false,
  clickHandler
}: LocalizedProductTileProps): JSX.Element => {
  const {
    values, setters, handlers
  } = useDisplayableProduct({
    imageUrl,
    name,
    sizes,
    subcategory,
    additionalOptions,
    categoryName,
    productIndex,
    showErrorModal,
    id,
    description,
    isOutOfStock,
    sodiumWarning,
    handleAddButtonClick,
    telemetryEventName,
    handleAnalyticsForAddToCartClick,
    handleAnalyticsForAddToCartClickFromRail,
    handleAnalyticsForAddToCartSuccessFromRail,
    handleAnalyticsForModifierSelected,
    userAction: buttonDetails?.ariaLabel ?? defaultButtonDetails.ariaLabel,
    preSelectedAdditionalOption,
    preSelectedQuantity,
    preSelectedSize,
    isSingleStepDeal,
    oneClickDefaults,
    type
  });

  const {
    quantity,
    selectedSize,
    optionsBySize,
    selectedAdditionalOption,
    hasMultipleSizes,
    hasAdditionalOptions,
    hasNutrition,
    formattedUnitPrice,
    buttonState,
    showInfoRail,
    lowerCaseCategory,
    isProductOutOfStock
  } = values;

  const {
    updateSize,
    updateAdditionalOption,
    updateQuantity,
    setShowInfoRail
  } = setters;

  const {
    handleAddToCart,
    handleAnalyticsForInfoRailSlideOut,
    handleInfoButtonClick
  } = handlers;

  const classes = useStyles();
  const { globalId: productId } = new ProductId(id);
  const classNameForGrid = hasMultipleSizes || hasAdditionalOptions
    ? `${classes.grid} ${classes.gridTemplateForDropdown}`
    : `${classes.grid} ${classes.gridTemplateWhenNoDropdown}`;
  const infoRailId = `product-info-rail-${name}`;
  const quantitySelectorInputLabel = `Quantity ${quantity} of ${name} selected.`;
  const btnDetails = { ...defaultButtonDetails, ...buttonDetails };
  const productNameWithoutSpaces = replaceSpacesWithDash(name);
  const isPizzaMelt = lowerCaseCategory === MELT.toLowerCase();
  const showSodiumWarning = sodiumWarning || selectedAdditionalOption?.sodiumWarning || selectedSize?.sodiumWarning || checkSodiumWarning(oneClickDefaults ?? []);

  return isProductOutOfStock ? (
    <SoldOutTile
      productId={productId}
      name={name}
      description={description}
      imageUrl={imageUrl}
    />
  ) : (
    <Grid className={classNameForGrid} key={productId} data-testid="localized-product-tile-container">
      <ProductImage imageUrl={imageUrl} description={description} />
      <ProductInfo
        name={name}
        description={description}
        lowerCaseCategory={lowerCaseCategory}
        formattedUnitPrice={formattedUnitPrice}
        showPrice={showPrice}
        sizes={sizes}
        categoryName={categoryName}
        handleInfoButtonClick={handleInfoButtonClick}
        sodiumWarning={showSodiumWarning}
      />
      {hasNutrition && (
        <RenderNutritionInfo
          productId={productId}
          selectedSize={selectedSize}
          selectedAdditionalOption={selectedAdditionalOption}
        />
      )}
      <ProductDropdownsWithAddToCart
        id={id}
        productNameWithoutSpaces={productNameWithoutSpaces}
        buttonDetails={btnDetails}
        productName={name}
        quantity={quantity}
        showQuantity={showQuantity}
        hasMultipleSizes={hasMultipleSizes}
        hasAdditionalOptions={!isPizzaMelt && hasAdditionalOptions}
        formattedUnitPrice={formattedUnitPrice}
        sizes={sizes}
        selectedSize={selectedSize}
        updateSize={(e) => updateSize(e, false)}
        additionalOptions={optionsBySize}
        selectedAdditionalOption={selectedAdditionalOption}
        updateAdditionalOption={(e) => updateAdditionalOption(e, false)}
        handleAddToCart={(
          (e) => handleAddToCart(e as unknown as React.MouseEvent<Element>, false))}
        buttonState={buttonState}
        updateQuantity={updateQuantity}
        quantitySelectorInputLabel={quantitySelectorInputLabel}
        isMyHutBoxProduct={isMyHutBoxProduct}
        clickHandler={clickHandler}
      />
      <InfoRail
        visible={showInfoRail}
        onClose={() => setShowInfoRail(false)}
        id={infoRailId}
      >
        <>
          <ProductInfoContent
            productNameWithoutSpaces={productNameWithoutSpaces}
            product={{
              id, name, description, sizes
            }}
            categoryName={categoryName}
            quantity={quantity}
            showQuantity={showQuantity}
            buttonState={buttonState}
            updateQuantity={updateQuantity}
            handleAddToCart={(e) => handleAddToCart(
              e as unknown as React.MouseEvent<Element>, true
            )}
            handleAnalyticsForInfoRailSlideOut={handleAnalyticsForInfoRailSlideOut}
            formattedUnitPrice={showPrice ? formattedUnitPrice : ''}
            hasMultipleSizes={hasMultipleSizes}
            hasAdditionalOptions={hasAdditionalOptions}
            selectedSize={selectedSize}
            updateSize={(e) => updateSize(e, true)}
            additionalOptions={optionsBySize}
            selectedAdditionalOption={selectedAdditionalOption}
            updateAdditionalOption={(e) => updateAdditionalOption(e, true)}
            productId={productId}
            hasNutrition={hasNutrition}
            quantitySelectorInputLabel={quantitySelectorInputLabel}
            buttonDetails={btnDetails}
            isMyHutBoxProduct={isMyHutBoxProduct}
          />
        </>
      </InfoRail>
    </Grid>
  );
};

export default LocalizedProductTile;
